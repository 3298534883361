import React from "react";
import PropTypes from "prop-types";
import {css} from "@emotion/react";
import {breakpoints} from "~styles/global";
import {Cross} from "../../Svg";
import Button from "~components/blocks/Button";
import RichText from "~components/RichText";
import { navigate } from "gatsby";

const AnnouncementPopup = ({className, heading, text, cta, link, close}) => {
  const {mobile} = breakpoints;

  return (
    <div
      className={className}
      css={css`
        display: grid;
        align-items: center;
        position: relative;
        padding: 100px 40px;
        background: var(--beige);
        max-width: 570px;
        box-sizing: border-box;
        margin: 25px;
        ${mobile} {
          max-width: 360px;
          padding: 80px 50px;
        }
      `}
    >
      <button
        onClick={close}
        css={css`
          position: absolute;
          z-index: 1;
          top: 26px;
          right: 26px;
          width: 20px;
          height: 20px;
        `}
      >
        <Cross
          css={css`
            width: 16px;
            height: 16px;
            ${mobile} {
              width: 12px;
              height: 12px;
            }
          `}
        />
      </button>
      <div
        css={css`
          display: grid;
        `}
      >
        <h4
          css={css`
            text-align: center;
            margin-bottom: 40px;
            ${mobile} {
              margin-bottom: 30px;
            }
          `}
        >
          {heading}
        </h4>
        <div
          css={css`
            p {
              text-align: center;
              &:last-child {
                padding-bottom: 50px;
                ${mobile} {
                  padding-bottom: 36px;
                }
              }
            }
          `}
        >
          <RichText content={text} />
        </div>
        <Button
          css={css`
            justify-self: center;
          `}
          onClick={() => {
            navigate(link);
            close();
          }}
        >
          {cta}
        </Button>
      </div>
    </div>
  );
};

AnnouncementPopup.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.string,
  link: PropTypes.string,
  close: PropTypes.func,
};

AnnouncementPopup.defaultProps = {
  // heading: `Anouncement Heading Here Over Two Lines of Copy`,
  // text: `Lorem, ipsum dolor sit amet consectetur adipisicing elit. Error tempore, illo non deserunt natus, accusantium animi perferendis dolorem aliquid nemo quae eos voluptate.`,
  // link: `/`,
  // cta: `CTA if needed`
};

export default AnnouncementPopup;
